.table {
  td,
  &:not(.table-bordered) th {
    border-top: none;
  }

  &:not(.table-sm):not(.table-md):not(.dataTable) td,
  &:not(.table-sm):not(.table-md):not(.dataTable) th {
    padding: 0 10px;
    height: 60px;
    // text-align: center;
    vertical-align: middle;
  }

  &:not(.table-sm) thead th {
    border-bottom: none;
    background-color: #ececec;
    color: #666;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &.table-md th,
  &.table-md td {
    padding: 10px 15px;
  }

  .team-member {
    position: relative;
    width: 30px;
    white-space: nowrap;
    border-radius: 1000px;
    vertical-align: bottom;
    display: inline-block;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      border: 0;
      border-radius: 1000px;
    }
  }

  .team-member-sm {
    width: 32px;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .team-member-sm:hover {
    webkit-transform: translateY(-4px) scale(1.02);
    -moz-transform: translateY(-4px) scale(1.02);
    -ms-transform: translateY(-4px) scale(1.02);
    -o-transform: translateY(-4px) scale(1.02);
    transform: translateY(-4px) scale(1.02);
    -webkit-box-shadow: 0 14px 24px rgba(75, 70, 124, 0.2);
    box-shadow: 0 14px 24px rgba(75, 70, 124, 0.2);
    z-index: 999;
  }

  .order-list li {
    img {
      border: 2px solid #ffffff;
      box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
    }

    + li {
      margin-left: -14px;
      background: transparent;
    }

    .badge {
      background: rgba(228, 222, 222, 0.8);
      color: #6b6f82;
      margin-bottom: 6px;
    }
  }

  .table-img img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid #bbbbbb;
    -webkit-box-shadow: 5px 6px 15px 0px rgba(49, 47, 49, 0.5);
    -moz-box-shadow: 5px 6px 15px 0px rgba(49, 47, 49, 0.5);
    -ms-box-shadow: 5px 6px 15px 0px rgba(49, 47, 49, 0.5);
    box-shadow: 5px 6px 15px 0px rgba(49, 47, 49, 0.5);
    text-shadow: 0 0 black;
  }
}

.table-links {
  color: color(fontdark);
  font-size: 12px;
  margin-top: 5px;
  opacity: 0;
  transition: all 0.3s;

  a {
    color: #666;
  }
}

table tr:hover .table-links {
  opacity: 1;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
.tblEditBtn {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #4f555a;
  padding: 0px 2px !important;
}
.tblEditBtn i {
  font-size: 20px;
  position: relative;
  line-height: 1;
  background-color: #e1fbe2;
  border-color: transparent;
  color: #4caf50;
  padding: 8px;
  height: 100%;
  border-radius: 5px;
}
.tblEditBtn :hover {
  background-color: #0ba83b;
  color: #fff;
}

.tblDelBtn {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #4f555a;
  padding: 0px 2px !important;
}
.tblDelBtn i {
  font-size: 20px;
  position: relative;
  line-height: 1;
  background-color: #fbe5dd;
  border-color: transparent;
  color: #fa6c39;
  padding: 8px;
  height: 100%;
  border-radius: 5px;
}
.tblDelBtn :hover {
  background-color: #f86e3c;
  color: #fff;
}

@include media-breakpoint-down(xs) {
  .table-responsive {
    table {
      min-width: 800px;
    }
  }
}
